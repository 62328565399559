import React from 'react';
import { useToast } from '../components/ui/use-toast';
import { Button } from '../components/ui/button';
import { ToastAction } from "../components/ui/toast"

function ShadcnDemo() {

  const { toast } = useToast();

  const clickActionButton = () => {
    alert("Action Button Clicked!");
  }
  return (
    <>
      <Button 
       variant="outline"
       onClick={() => {
         toast({
           variant: "outline",
           title: "Base toast message",
         })
       }}
      >
        Base Toast
      </Button>

      <br></br>
      <br></br>
      
      <Button
        onClick={() =>
          toast({
            variant: "outline",
            title: "Success toast message",
            action: <ToastAction >Try again</ToastAction>,
          })
        }
      >
        Success Toast
      </Button>

      <br></br>
      <br></br>

      <Button
        onClick={() =>
          toast({
            variant: "outline",
            title: "Error toast message",
            action: <ToastAction >Try again</ToastAction>,
          })
        }
      >
        Error Toast
      </Button>

      <br></br>
      <br></br>

      <Button
        onClick={() =>
          toast({
            variant: "outline",
            title: "Error toast message",
            action: <ToastAction altText="Click here" onClick={()=> clickActionButton()}> Click here </ToastAction>,
          })
        }
      >
        Action Toast
      </Button>
    </>
  )
}

export default ShadcnDemo;
