import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import ChakraDemo from './pages/ChakraDemo';
import './styles/App.css';
import ShadcnDemo from './pages/ShadcnDemo';
import { Toaster } from '../src/components/ui/toaster';

const { ToastContainer } = createStandaloneToast()

function App() {
  return (
    <div className="App">
      <div className='row'>
        <div className='col-6'>
          <div className='App-header chakra-demo'>
            <h3>Chakra Demo</h3>
          </div>
          <ChakraProvider>
            <ChakraDemo/>
            <ToastContainer />
          </ChakraProvider>
        </div>

        <div className='col-6'>
          <div className='App-header shadcn-demo'>
            <h3>Shadcn Demo</h3>
          </div>
          <ChakraProvider>
            <ShadcnDemo/>
             <Toaster/>
          </ChakraProvider>
        </div>
      </div>
    </div>
  );
}

export default App;
