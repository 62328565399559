import { Button, useToast } from '@chakra-ui/react'
import CustomChakraToast from '../components/CustomChakraToast';
import successPng from "../images/success.png";
import errorPng from "../images/error.png";
import basePng from "../images/base.png";
import closePng from "../images/close.png";
import React from 'react';

function ChakraDemo() {

  const toast = useToast();

  const clickActionButton = () => {
    alert("Action Button Clicked!");
  }

  return (
    <>
      <Button
        onClick={() =>
          toast({
            position: 'bottom-left',
            duration: 5000,
            render: () => (
              <CustomChakraToast
                image={basePng}
                message="Base toast message"
              />
            ),
          })
        }
      >
        Base Toast
      </Button>

      <br></br>
      <br></br>
      
      <Button
        onClick={() =>
          toast({
            position: 'bottom-left',
            duration: 5000,
            render: () => (
              <CustomChakraToast
                image={successPng}
                message="Success toast message"
              />
            ),
          })
        }
      >
        Success Toast
      </Button>

      <br></br>
      <br></br>

      <Button
        onClick={() =>
          toast({
            position: 'bottom-left',
            duration: 5000,
            render: () => (
              <CustomChakraToast
                image={(errorPng)}
                message="Error toast message"
              />
            ),
          })
        }
      >
        Error Toast
      </Button>

      <br></br>
      <br></br>

      <Button
        onClick={() =>
          toast({
            position: 'bottom-left',
            duration: null,
            render: ({ onClose }) => (
              <CustomChakraToast
                image = {basePng}
                message = "Action toast message"
                buttonText = "Click Here"
                onAction = { () => clickActionButton()}
                closeImg = {closePng}
                onClose = {onClose}
              />
            ),
          })
        }
      >
        Action Toast
      </Button>
    </>
  )
}

export default ChakraDemo;
