import { Button } from "@chakra-ui/react";
import React from "react";

const CustomChakraToast = (props) => {

  return (
    <h1>
      {/* Render Toast Image */}
      {props.image && <img src={props.image} alt="Toast icon"></img>}

      {/* Display toast message */}
      {props.message}

      {/* Toast actions will be here */}
      {props.onAction && props.buttonText &&
        <Button onClick={props.onAction} type='button' variant='ghost' size='xs' colorScheme="white">
          {props.buttonText}
        </Button>
      }

      {/* Display close icon */}
      {props.closeImg && <img src={props.closeImg} onClick={props.onClose} alt="Close icon"></img>}
    </h1>
  )
}

export default CustomChakraToast;